import HomeBg from './home-bg/HomeBg.vue'
import Header from '../../shared/header/Header.vue'
import Footer from '../../shared/footer/Footer.vue'
import NotificationsModal from '../../shared/notifications-modal/NotificationsModal.vue'

export default {
  name: 'Home',
  components: {
    HomeBg,
    Header,
    Footer,
    NotificationsModal
  },
  beforeMount () {
    document.querySelector('html').classList.add('home-scroller')
  },
  beforeDestroy () {
    document.querySelector('html').classList.remove('home-scroller')
  }
}
