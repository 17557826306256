import { render, staticRenderFns } from "./HomeBg.vue?vue&type=template&id=447b4d5a&scoped=true"
import script from "./HomeBg.js?vue&type=script&lang=js&external"
export * from "./HomeBg.js?vue&type=script&lang=js&external"
import style0 from "./HomeBg.scss?vue&type=style&index=0&id=447b4d5a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447b4d5a",
  null
  
)

export default component.exports