export default {
  name: 'NotificationsModal',
  methods: {
    show () {
      const d = new Date()
      const localStorageTime = parseInt(localStorage.getItem('notificationsModalShowDate'))
      const hideNotificationsModal = sessionStorage.getItem('hideNotificationsModal')

      if (d.getTime() < localStorageTime || hideNotificationsModal === 'true') {
        return
      }

      this.$modal.show('notifications-modal')
    },
    hide () {
      this.$modal.hide('notifications-modal')
      sessionStorage.setItem('hideNotificationsModal', 'true')
    },
    never () {
      const d = new Date()
      localStorage.setItem('notificationsModalShowDate', (d.getTime() + 30 * 24 * 60 * 60 * 1000).toString())
      this.hide()
    },
    subscribe () {
      this.never()
      window._izq = window._izq || []; window._izq.push(['triggerPrompt'])
    }
  },
  mounted () {
    this.show()
  }
}
